import {
  catchErr,
  validateUserName,
  validatePsw,
  validatePhone,
  validateEmail,
} from "@/js/validate";
import {
  tokenSend
} from "@/passToken";
import {
  Dialog
} from "vant";
import {
  check
} from "../../passToken/sso"
import {
  sendRegisterCode,
  sendResetPswCode,
  loginByTel,
  loginByUsername,
  loginByEmail,
  wechatgetLoginUrl,
  sendResetPswEmailCode,
  getWxQrCode,
  loginByWX,
  sendLoginCode,
  sendRegisterEmailCode,
  registerdetect_username,
  // 用户微信授权操作
  wechatdevice,
  // 获取授权信息
  wechatget_auth_information,
  userinfo,
  getips,
  registerconfirmed_email_code,
  registerconfirmed_tel_code,
  usersendcode_bounduid,
  wechatis_subscribe_wechat_official_account,
  wenotice,
  accormerge
} from "@/api/api";

//存取cookies
function setCookie(name, value) {
  delCookie(name); // 删除旧的 cookie
  let Days = 1; // 设置 cookie 有效期为 1 天
  let exp = new Date();
  exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000); // 当前时间 + 1 天

  // 设置 cookie，包含 SameSite=None 和 Secure
  document.cookie =
    name +
    "=" +
    escape(value) +
    ";expires=" +
    exp.toUTCString() +
    ";path=/;SameSite=None;Secure";
}

//读取cookies
function getCookie(name) {
  let arr,
    reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  if ((arr = document.cookie.match(reg))) {
    return unescape(arr[2]);
  } else {
    return null;
  }
}

function deleteCookie(name, domain) {
  // 设置过期时间为过去的时间，以删除 cookie
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=" + domain + "; SameSite=None; Secure";
}

function getAllCookies() {
  // 获取当前域名下的所有 cookie
  return document.cookie.split(';').map(cookie => cookie.trim());
}
// function deleteAllCookies() {
function deleteAllTokens() {
  // 获取所有 cookie 并删除与 "token" 相关的 cookie
  let cookies = getAllCookies();
  let currentDomain = window.location.hostname;

  // 获取子域和父域
  let domainParts = currentDomain.split('.');
  let currentDomainBase = '.' + domainParts.slice(-2).join('.'); // 例如 ".example.com"

  cookies.forEach(cookie => {
    let cookieName = cookie.split('=')[0];

    // 检查是否为 "token" cookie
    if (cookieName.includes('token')) {
      // 删除当前域名下的 cookie
      deleteCookie(cookieName, currentDomain);

      // 尝试删除父域共享的 cookie
      deleteCookie(cookieName, currentDomainBase);

      // 如果有更多子域，还可以尝试删除其他子域的 cookie
      // deleteCookie(cookieName, '.subdomain.example.com'); // 可选
    }
  });

  // console.log('所有 token 已删除');
}
// }

//删除cookies
function delCookie(name) {
  let exp = new Date();
  exp.setTime(exp.getTime() - 1);
  let cval = getCookie(name);
  if (cval != null)
    document.cookie = name + "=" + cval + ";expires=" + exp.toUTCString();
}

export default {
  name: "loginPage",
  data() {
    var checkName = (rule, value, callback) => {
      // console.log(rule, 123);
      if (!value) {
        return callback(new Error(this.$t("noName")));
      } else if (!validateUserName(value)) {
        callback(new Error(this.$t("nameErr")));
      }
      // if()
      registerdetect_username({
        username: value
      }).then((res) => {
        callback();
      }).catch((err) => {
        callback(new Error(this.$t("The_current_user_name_already_exists")));
        return
      })
    };

    var checkNames = (rule, value, callback) => {
      // console.log(rule, 123);
      if (!value) {
        return callback(new Error(this.$t("noName")));
      } else if (!validateUserName(value)) {
        callback(new Error(this.$t("nameErr")));
      }
      callback();
      // if()
      // registerdetect_username({
      //   username: value
      // }).then((res) => {
      //   
      // }).catch((err) => {
      //   callback(new Error(this.$t("The_current_user_name_already_exists")));
      //   return
      // })
    };
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t("noPhone")));
      } else if (!validatePhone(value)) {
        return callback(new Error(this.$t("phoneErr")));
      }
      callback();
    };
    var checkEmail = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t("noEmail")));
      } else if (!validateEmail(value)) {
        callback(new Error(this.$t("emailErr")));
      }
      callback();
    };
    var checkPsw = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("noPsw")));
      } else if (!validatePsw(value)) {
        callback(new Error(this.$t("pswErr")));
      } else {
        callback();
      }
    };
    var checkConfirmPsw = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("noCofirmPsw")));
      } else if (value !== this.ruleForm.psw) {
        callback(new Error(this.$t("confirmPswErr")));
      } else {
        callback();
      }
    };
    return {
      lang: localStorage.lang,
      isMobile: false,
      wxauthorization: false,
      btnCodeDisabled: true,
      btnCodeText: this.$t("getCode"),
      btnCodeText1: this.$t("getCode"),
      registerType: '',
      accmerge: null,
      address: 'zh_CN',
      qrUrl: "",
      userinfo: '',
      devicename: 'pc',
      showQrcodeDialog: false,
      step: 1,
      // registerType: 'wx',
      uerror: null,
      uccode: '',
      visible: false,
      type: "nameRuleForm",
      tokens: '',
      wxImg: "",
      usernameErr: "",
      id: this.$route.query.id || '',
      uid: this.$route.query.uid || '',
      passwordErr: "",
      codeErr: "",
      telErr: "",
      emailErr: "",
      //用户名登入时的form
      nameRuleForm: {
        name: "",
        psw: "",
      },
      phoneRuleForm: {
        phone: "",
        code: "",
      },
      emailRuleForm: {
        email: "",
        psw: "",
      },
      checked: false,
      //用户名登入时的form
      ruleForm: {
        name: "",
        psw: "",
        email: "",
        confirmPsw: "",
        phone: "",
        code: "",
      },
      rules: {
        name: [{
          validator: checkNames,
          trigger: "blur",
          required: true
        }],
        names: [{
          validator: checkName,
          trigger: "blur",
          required: true
        }],
        phone: [{
          validator: checkPhone,
          trigger: "blur",
          required: true
        }],
        psw: [{
          validator: checkPsw,
          trigger: "blur",
          required: true
        }],
        email: [{
          validator: checkEmail,
          trigger: "blur",
          required: true
        }],
        code: [{
          required: true,
          message: this.$t("noCode")
        }],
        code2: [{
          required: true,
          message: this.$t("noCode")
        }],
        code3: [{
          required: true,
          message: this.$t("noCode")
        }],
        codes: [{
          required: true,
          message: this.$t("noCode")
        }],
        confirmPsw: [{
          validator: checkConfirmPsw,
          trigger: "blur",
          required: true
        }, ],
      },
    };
  },
  created() {
    // alert('456789')
    console.log(location.href, 123);
    var that = this;
    document.onkeydown = function (e) {
      var key = window.event.keyCode;
      if (key == 13) {
        that.submit();
      }
    };

  },

  mounted() {
    // console.log(this.$route.query);
    getips({

    }).then((res) => {
      // console.log(res, 987);
      if (res.data[0] == '未知') {
        this.address = 'zh_CN'
      }
      if (res.data[0].country.iso_code == 'CN') {
        this.address = 'zh_CN'
        // i18n.global.locale = 'zh_CN'
      } else {
        // i18n.global.locale = 'en_US'
        this.address = 'en'
      }
    })
    // check()
    this.inWX();
    this.countDownInit();
    this.tothelogin();
    // Dialog.confirm({
    //   title: "合并账号操作",
    //   message: "当前是否要合并账号",
    //   confirmButtonText: "确定",
    //   cancelButtonText: "取消",
    // })
    // .then(() => {

    //   // toContest();
    // })
  },
  methods: {
    codepost(e) {
      if (e.length != 6) return;
      wenotice({
        uid: this.userinfo.id,
        code: e
      }).then((res) => {
        if (res.data.mess == '验证码不存在!') {
          this.$msg(res.data.mess);
        } else if (res.data.mess == "此微信已存在!") {
          Dialog.confirm({
              title: "合并账号操作",
              message: "当前微信账号已存在,是否执行合并操作",
              confirmButtonText: "确定",
              cancelButtonText: "取消",
            })
            .then(() => {
              accormerge({
                uid: this.userinfo.id,
                otherId: res.data.otherId,
                element: 'wechat',
                code: e,
              }).then((res) => {
                if (res.data.mess == '验证码输入有误！') {
                  this.$msg(res.data.mess);
                  return;
                }
                // console.log(res, 123456);
                this.$msg(this.$t("Successful_merger"));
                this.showQrcodeDialog = false;
                this.step = 3
                // if (!this.userinfo.wx_openid) {
                //   this.step = 5
                // } else {
                //   this.step = 3
                  // this.btnCodeText = this.$t("getCode");
                // }
              });
            })
            .catch(() => {
              return;
              // jumpTo(`/artwork/${res.data.id}`);
            });


        } else {
          this.$msg('微信绑定成功');
          // if (res.data.mess) {
          this.uccode = "";
          this.showQrcodeDialog = false;
          this.step = 3
        }

        // setTimeout(() => {
        //   location.reload();
        // }, 1000);
        // }

        // state.wecontimg = res.data.url;
        // state.showQrcodeDialog = true;
      });
    },
    getuserinfo(e) {
      // console.log('====================================');
      // console.log(this.address);
      // console.log('====================================');
      setCookie("token", e);
      this.tokens = e
      userinfo({}).then((res) => {
        this.userinfo = res.data
        if (this.userinfo.email) {
          this.ruleForm.email = this.userinfo.email
        }
        // console.log(res, 1234, this.type);
        if (this.type === "nameRuleForm" || this.type === "mNameRuleForm") {
          // console.log('====================================');
          // console.log(this.registerType);
          // console.log('====================================');
          if (!res.data.email) {
            this.registerType = 'email'
            this.step = 2
          } else if (!res.data.tel) {
            this.registerType = 'phone'

            this.step = 2
          } else if (!res.data.wx_openid) {
            this.step = 5
          }

          if (res.data.email && res.data.tel && res.data.wx_openid) {
            this.afterGotToken(this.tokens);
          }

        } else if (
          this.type === "phoneRuleForm" ||
          this.type === "mPhoneRuleForm"
        ) {
          if (!res.data.email) {
            this.registerType = 'email'
            this.step = 2
          } else if (!res.data.wx_openid) {
            this.step = 5
          }
          if (res.data.email && res.data.tel && res.data.wx_openid) {
            // console.log('====================================');
            // console.log(12344);
            // console.log('====================================');
            this.afterGotToken(this.tokens);
          }
        } else if (
          this.type === "emailRuleForm" ||
          this.type === "mEmailRuleForm"
        ) {
          if (!res.data.tel) {
            this.registerType = 'phone'
            this.step = 2
          } else if (!res.data.wx_openid) {
            this.step = 5
          }
          if (res.data.email && res.data.tel && res.data.wx_openid) {
            this.afterGotToken(this.tokens);
          }

        }
        // if()
      })
    },
    skipstep() {
      this.step = 3
    },
    tologin3() {
      console.log('====================================');
      console.log(this.tokens, 123, getCookie('token'));
      console.log('====================================');
      this.afterGotToken(this.tokens || getCookie('token'));
    },
    tologin() {
      if (this.wxauthorization) {
        this.step = 4
      } else {
        this.$msg('请您在微信授权后再进行下一步操作');
      }
    },
    tologins(e) {
      if (e == 0) {
        this.step = 3
      } else {
        wechatis_subscribe_wechat_official_account({
          tag_email: this.ruleForm.email || this.userinfo.email || this.emailRuleForm.email
        }).then((res) => {
          // console.log(res,1);
          if (res.success) {
            this.step = 3
          } else {
            this.$msg(res.message);
          }
        }).catch((err) => {
          // console.log(err,12);

        })
      }


    },

    tothelogin() {
      const urlParams = new URLSearchParams(window.location.search);
      const step = urlParams.get('step');
      if (step == 5) {
        const cachedRuleForm = localStorage.getItem('ruleForm');
        // console.log(123);
        this.step = 5
        if (cachedRuleForm) {
          const cachedData = JSON.parse(cachedRuleForm);
          // 恢复缓存的数据
          this.ruleForm = cachedData || this.ruleForm;
          wechatget_auth_information({
            tag_email: this.ruleForm.email,
          }).then((res) => {
            if (res.success) {
              this.wxauthorization = res.message
              this.visible = false
              // clearInterval(refreshRequest);
              // this.afterGotToken(res.token);
            } else {
              this.$msg(res.message);
            }
          })

          // 根据需要恢复其他表单字段
        }
      }

    },


    inDomain(url) {
      let href = url.split("/")[2];
      return href.indexOf(process.env.VUE_APP_DOMAIN) !== -1;
    },
    countDownInit() {
      const preTime = localStorage.getItem(this.$route.path); //获取点击按钮时的时间
      if (!preTime) return;
      const timeNum = parseInt((Date.now() - preTime) / 1000);
      if (timeNum >= 60) {
        localStorage.removeItem(this.$route.path);
      } else {
        this.countDown(60 - timeNum);
      }
    },
    mWxLogin() {
      // console.log(this.$route.query, 434);
      // return;
      // return;
      // console.log(id, uid, 123);
      const id = this.$route.query.id;
      const uid = this.$route.query.uid;
      const redirect = this.$route.query
      if (this.$route.query.id && this.$route.query.uid) {
        location.href = process.env.VUE_APP_API + "/wechat/getLoginUrl?redirect_url=" + this.$route.query.redirect + '+id=' + id + '+uid=' + uid;
      } else if (this.$route.query.id) {
        location.href = process.env.VUE_APP_API + "/wechat/getLoginUrl?redirect_url=" + this.$route.query.redirect + '+id=' + id;
      } else {
        location.href = process.env.VUE_APP_API + "/wechat/getLoginUrl"
      }
      // if () {
      //   // wechatgetLoginUrl({
      //   //   redirect_url: this.$route.query.redirect + '?id=' + id + '?uid=' + uid
      //   // }).then((res) => {

      //   // })

      // }
      // location.href = process.env.VUE_APP_API + "/wechat/getLoginUrl?redirect_url=" + this.$route.query.redirect;
    },
    inWX() {
      var ua = window.navigator.userAgent.toLowerCase();
      return ua.match(/micromessenger/i) == "micromessenger";
    },
    //倒计时
    countDown(time) {
      let timeNum = time;
      this.btnCodeDisabled = true;
      const timer = setInterval(() => {
        this.btnCodeText = timeNum + "s";
        timeNum--;
        if (timeNum <= 0) {
          clearInterval(timer);
          this.checkdisabled = false;
          this.btnCodeText = this.$t("getCode");
          localStorage.removeItem(this.$route.path);
        }
      }, 1000);
    },

    countDown1(time) {
      clearInterval(timer);
      let timeNum = time;
      this.btnCodeDisabled = true;
      const timer = setInterval(() => {
        this.btnCodeText1 = timeNum + "s";
        timeNum--;
        // if(this.btnCodeText)
        if (timeNum <= 0) {
          clearInterval(timer);
          this.checkdisabled = false;
          this.btnCodeText1 = this.$t("getCode");
          localStorage.removeItem(this.$route.path);
        }
      }, 1000);
    },
    /**
     * 路由跳转公共方法
     * @param path
     */
    toPage(path) {
      // this.$router.push({path, query: {redirect: this.$route.query.redirect}});
      if (this.$route.query.redirect) {
        location.href = `${location.origin}${path}?redirect=${this.$route.query.redirect}`;
      }
      location.href = `${location.origin}${path}`;
    },
    submit3(name, uname) {
      if (uname == 'v2') {
        this.$refs[name].validate((valid) => {
          if (valid) {
            if (this.$route.path === "/login") {
              if (this.registerType == 'email') {
                if (this.accmerge) {
                  Dialog.confirm({
                      title: "合并账号操作",
                      message: "当前邮箱账号已存在,是否执行合并操作",
                      confirmButtonText: "确定",
                      cancelButtonText: "取消",
                    })
                    .then(() => {
                      accormerge({
                        uid: this.userinfo.id,
                        otherId: this.accmerge,
                        element: 'email',
                        code: this.ruleForm.code3,
                      }).then((res) => {
                        if (res.data.mess == '验证码输入有误！') {
                          this.$msg(res.data.mess);
                          return;
                        }
                        // console.log(res, 123456);
                        this.$msg(this.$t("Successful_merger"));
                        if (!this.userinfo.wx_openid) {
                          this.step = 5
                        } else {
                          this.step = 3
                          this.btnCodeText = this.$t("getCode");
                          // this.btnCodeText = this.$t("getCode");
                          // clearInterval(timer);
                        }
                      });
                      // toContest();
                    })
                    .catch(() => {
                      return;
                      // jumpTo(`/artwork/${res.data.id}`);
                    });
                } else {
                  usersendcode_bounduid({
                    element: 'email',
                    number: this.ruleForm.email,
                    sendcode: this.ruleForm.code3,
                    position: 'login'
                  }).then((res) => {
                    if (res.data.mess == '验证码不存在!') {
                      this.$msg(res.data.mess);
                      return;
                    }
                    if (res.data.mess == '验证码输入有误!') {
                      this.$msg(res.data.mess);
                      return;
                    }
                    if (res.data.message == '此号已存在!') {
                      this.$msg(res.data.message);
                      return;
                    }
                    if (!this.userinfo.wx_openid) {
                      this.step = 5
                    } else {
                      if (!this.userinfo.tel) {
                        this.registerType = 'phone'
                        this.btnCodeText = this.$t("getCode");
                      } else {
                        this.step = 3
                      }
                      // this.step = 3

                    }


                  }).catch((err) => {
                    if (err.message == '非法请求') {
                      this.codeErr = err.errors.email
                    }
                    this.codeErr = err.message
                    return;
                    // console.log(err, '邮箱', 12355);
                  })
                }




                // registerconfirmed_email_code({
                //   email: this.ruleForm.email,
                //   code: this.ruleForm.code3,
                //   lang: this.address
                // }).then((res) => {
                //   console.log(res, '邮箱', 12355);
                //   if (!this.userinfo.wx_openid) {
                //     this.step = 5
                //   } else {
                //     this.step = 3
                //   }


                // }).catch((err) => {
                //   if (err.message == '非法请求') {
                //     this.codeErr = err.errors.email
                //   }
                //   this.codeErr = err.message
                //   return;
                //   // console.log(err, '邮箱', 12355);
                // })
                // console.log(2);

              } else if (this.registerType == 'phone') {
                if (this.accmerge) {
                  Dialog.confirm({
                      title: "合并账号操作",
                      message: "当前手机号账号已存在,是否执行合并操作",
                      confirmButtonText: "确定",
                      cancelButtonText: "取消",
                    })
                    .then(() => {
                      accormerge({
                        uid: this.userinfo.id,
                        otherId: this.accmerge,
                        element: 'tel',
                        code: this.ruleForm.code2,
                      }).then((res) => {
                        if (res.data.message == '验证码输入有误！') {
                          this.$msg(res.data.message);
                          return;
                        }
                        this.$msg(this.$t("Successful_merger"));
                        if (!this.userinfo.wx_openid) {
                          this.step = 5
                        } else {
                          this.step = 3
                          this.btnCodeText = this.$t("getCode");
                          // clearInterval(timer);
                        }
                        // console.log(res, 123456);
                        // ElMessage({
                        //   duration: 5000,
                        //   message: res.data.mess,
                        //   type: res.data.mess == "合并成功！" ? "success" : "error",
                        // });
                        // setTimeout(() => {
                        //   location.reload();
                        // }, 2000);
                      }).catch((err) => {
                        this.$msg(err);
                      });
                      // toContest();
                    })
                    .catch(() => {
                      return;
                      // jumpTo(`/artwork/${res.data.id}`);
                    });
                } else {
                  usersendcode_bounduid({
                    element: 'tel',
                    number: this.ruleForm.phone,
                    sendcode: this.ruleForm.code2,
                    position: 'login'
                  }).then((res) => {
                    if (res.data.mess == '验证码不存在!') {
                      this.$msg(res.data.mess);
                      return;
                    }
                    if (res.data.mess == '验证码输入有误!') {
                      this.$msg(res.data.mess);
                      return;
                    }
                    if (res.data.mess == '此号已存在!') {
                      this.$msg(res.data.mess);
                      return;
                    }
                    if (!this.userinfo.wx_openid) {
                      this.step = 5
                    } else {
                      this.step = 3
                      this.btnCodeText = this.$t("getCode");
                      // clearInterval(timer);
                    }
                  }).catch((err) => {
                    if (err.message == '非法请求') {
                      this.codeErr = err.errors.email
                    }
                    this.codeErr = err.message
                    return;
                    // console.log(err, '邮箱', 12355);
                  })
                }
                // console.log(3);
                // registerconfirmed_tel_code({
                //   tel: this.ruleForm.phone,
                //   code: this.ruleForm.code2
                // }).then((res) => {
                //   console.log(res, '手机号', 12355);
                //   if (!this.userinfo.wx_openid) {
                //     this.step = 5
                //   } else {
                //     this.step = 3
                //   }
                // }).catch((err) => {
                //   if (err.message == '非法请求') {
                //     this.codeErr = err.errors.tel
                //   }
                //   this.codeErr = err.message
                //   return;
                //   // console.log(err, '手机号', 12355);
                // })

              }
            }
          }

        })
        this.errReset();
      } else {
        return false;
      }
    },

    // 点击发送验证码按钮
    clickBtnCode(e) {
      // console.log('====================================');
      // console.log(e,123);
      // console.log('====================================');
      // return;
      let that = this;
      this.countDown(60);
      switch (this.$route.path) {
        case "/mLogin":
        case "/login":
          if (e == '1') {
            sendLoginCode({
                tel: this.phoneRuleForm.phone
              })
              .then((res) => {
                this.$msg(this.$t("codeHasBeenSent"));
                localStorage.setItem(this.$route.path, Date.now());
                console.log(res)
              })
              .catch((err) => {
                catchErr(err, this);
              });

          } else {
            usersendcode_bounduid({
                element: this.registerType == 'email' ? 'email' : 'tel',
                number: this.registerType == 'email' ? this.ruleForm.email : this.ruleForm.phone,
                sendcode: true,
                position: 'login'
              })
              .then((res) => {
                if (res.data.mess == '验证码不存在!') {
                  this.$msg(res.data.mess);
                  return;
                }
                if (res.data.mess == '验证码输入有误!') {
                  this.$msg(res.data.mess);
                  return;
                }
                if (res.data.message == '此号已存在!') {
                  this.$msg(res.data.message);
                  return;
                }
                // if()
                this.$msg(this.$t("codeHasBeenSent"));
                if (res.data.otherId) {
                  this.accmerge = res.data.otherId
                } else {
                  this.accmerge = null
                }
                localStorage.setItem(this.$route.path, Date.now());
                console.log(res)
              })
              .catch((err) => {
                catchErr(err, this);
              });
          }
          break;
        case "/mRegister":
        case "/register":
          if (this.registerType === "phone") {
            sendRegisterCode({
                tel: this.ruleForm.phone
              })
              .then((res) => {
                this.$msg(this.$t("codeHasBeenSent"));
                localStorage.setItem(this.$route.path, Date.now());
              })
              .catch((err) => {
                catchErr(err, this);
              });
          } else if (this.registerType === "email") {
            sendRegisterEmailCode({
                email: this.ruleForm.email
              })
              .then((res) => {
                this.$msg(this.$t("codeHasBeenSent"));
                localStorage.setItem(this.$route.path, Date.now());
              })
              .catch((err) => {
                catchErr(err, this);
              });
          }

          break;
        case "/mResetPsw":
        case "/resetPsw":
          if (this.registerType === "phone") {
            sendResetPswCode({
                tel: this.ruleForm.phone
              })
              .then((res) => {
                this.$msg(this.$t("codeHasBeenSent"));
                localStorage.setItem(this.$route.path, Date.now());
              })
              .catch((err) => {
                catchErr(err, this);
              });
          } else if (this.registerType === "email") {
            sendResetPswEmailCode({
                email: this.ruleForm.email
              })
              .then((res) => {
                this.$msg(this.$t("codeHasBeenSent"));
                localStorage.setItem(this.$route.path, Date.now());
              })
              .catch((err) => {
                catchErr(err, this);
              });
          }

          break;
      }
    },
    clickBtnCode1() {
      let that = this;
      this.countDown1(60);
      switch (this.$route.path) {
        case "/mLogin":
        case "/login":
          sendLoginCode({
              tel: this.phoneRuleForm.phone
            })
            .then((res) => {
              this.$msg(this.$t("codeHasBeenSent"));
              localStorage.setItem(this.$route.path, Date.now());
              console.log(res)
            })
            .catch((err) => {
              catchErr(err, this);
            });
          break;
        case "/mRegister":
        case "/register":
          if (this.registerType === "phone") {
            sendRegisterEmailCode({
                email: this.ruleForm.email
              })
              .then((res) => {
                this.$msg(this.$t("codeHasBeenSent"));
                localStorage.setItem(this.$route.path, Date.now());
              })
              .catch((err) => {
                catchErr(err, this);
              });

          } else if (this.registerType === "email") {
            sendRegisterCode({
                tel: this.ruleForm.phone
              })
              .then((res) => {
                this.$msg(this.$t("codeHasBeenSent"));
                localStorage.setItem(this.$route.path, Date.now());
              })
              .catch((err) => {
                catchErr(err, this);
              });
          }

          break;
        case "/mResetPsw":
        case "/resetPsw":
          if (this.registerType === "phone") {
            sendResetPswCode({
                tel: this.ruleForm.phone
              })
              .then((res) => {
                this.$msg(this.$t("codeHasBeenSent"));
                localStorage.setItem(this.$route.path, Date.now());
              })
              .catch((err) => {
                catchErr(err, this);
              });
          } else if (this.registerType === "email") {
            sendResetPswEmailCode({
                email: this.ruleForm.email
              })
              .then((res) => {
                this.$msg(this.$t("codeHasBeenSent"));
                localStorage.setItem(this.$route.path, Date.now());
              })
              .catch((err) => {
                catchErr(err, this);
              });
          }

          break;
      }
    },
    //phone输入框值发生改变时触发回调
    phoneInputChange(val) {
      if (validatePhone(val)) {
        this.btnCodeDisabled = false;
      } else {
        this.btnCodeDisabled = true;
      }
    },
    //phone输入框值发生改变时触发回调
    emailInputChange(val) {
      if (validateEmail(val)) {
        this.btnCodeDisabled = false;
      } else {
        this.btnCodeDisabled = true;
      }
    },
    /*
     * 点击微信logo触发pop
     */
    clickWxLogo() {
      if (this.$route.path == '/register') {
        this.visible = !this.visible;
        setTimeout(() => {
          this.$refs.dcPopover.updatePopper();
        }, 210);
      } else if (this.$route.path == '/login') {
        if (!this.userinfo.email || !this.ruleForm.email) {
          this.visible = !this.visible;
          setTimeout(() => {
            this.$refs.dcPopover.updatePopper();
          }, 210);
        } else {
          this.showQrcodeDialog = true
        }
      }
      // if(!this.userinfo.email){}

    },
    /*
     * 登录成功后跳转
     */
    afterGotToken(token) {
      // localStorage.setItem('token', token)
      // console.log(token, 12345);
      // return;
      setCookie("token", token);
      // return;
      // console.log(location.search, 333);
      let url = decodeURIComponent(location.search.replace("?redirect=", ""));

      // let e = encodeURIComponent(url)
      // let isSafari =
      //   navigator.userAgent.indexOf("Safari") > -1 &&
      //   navigator.userAgent.indexOf("Chrome") < 0;
      // console.log(url,666666);
      setTimeout(() => {
        // console.log('====================================');
        // console.log(url,123);
        // console.log('====================================');

        if (!url) {
          url = process.env.VUE_APP_MAINWEBSITE;
        }
        let host = url.split("//")[1].split('/')[0]
        let http = url.split('//')[0]
        // console.log(http, host, url, 876545);
        // let u = decodeURIComponent("http://meshmellow.cn/detail/ex?id=31&uid=30922");
        // console.log(`${http}//${host}/t?t=${token}&r=${url}`, '重定向的值', url, this.$route.query.redirect);
        // return;
        // return;
        // return;
        // return;
        // 之前
        // console.log('====================================');
        console.log(`${http}//${host}/t?t=${token}&r=${url}`);
        // console.log('====================================');

        // return;
        // return;
        location.href = `${http}//${host}/t?t=${token}&r=${url}`
        // 现在
        // location.href = url

        // location.href = process.env.VUE_APP_MAINWEBSITE + `t?t=${token}&r=${url}`;
        // 新增轮询跳转注入cookie，以下逻辑废弃
        // if (this.$route.query.redirect) {

        //   if (!this.inDomain(url)) {
        //     location.href = url + `?t=${token}`;
        //     return;
        //   } else {
        //     return (location.href = url);
        //   }
        // } else {
        //   return (location.href = process.env.VUE_APP_MAINWEBSITE);
        // }
      }, 0);
    },
    /*
     * 获取微信二维码
     */
    wxLogin() {
      // getWxQrCode().then((res) => {
      //   this.qrUrl = res.url;
      //   let refreshRequest = setInterval(() => {
      //     loginByWX({
      //       uuid: res.uuid
      //     }).then((res) => {
      //       if (res.token) {
      //         clearInterval(refreshRequest);
      //         this.afterGotToken(res.token);
      //       }
      //       if (!this.visible) {
      //         clearInterval(refreshRequest);
      //       }
      //     });
      //   }, 2000);
      // });
      console.log('====================================');
      console.log(this.registerType, 1, this.ruleForm.email, 2, this.userinfo.email, 3, this.emailRuleForm.email, 4, this.registerType);
      console.log('====================================');
      if (!this.userinfo.email) {
        // delCookie('token')
        deleteAllTokens();
        console.log('====================================');
        console.log(2323);
        console.log('====================================');
        // return;
        getWxQrCode().then((res) => {

          this.qrUrl = res.url;
          let refreshRequest = setInterval(() => {
            loginByWX({
              uuid: res.uuid
            }).then((res) => {

              if (res.token) {
                clearInterval(refreshRequest);
                setCookie("token", res.token);
                this.tokens = res.token
                userinfo({}).then((res) => {
                  this.userinfo = res.data
                  if (!res.data.email) {
                    this.registerType = 'email'
                    this.step = 2
                  } else if (!res.data.tel) {
                    this.registerType = 'phone'
                    this.step = 2
                  }
                  if (res.data.email && res.data.tel && res.data.wx_openid) {
                    // return;
                    this.afterGotToken(this.tokens);
                  }

                })
                // this.afterGotToken(res.token);

              }
              if (!this.visible) {
                clearInterval(refreshRequest);
              }
            });
          }, 2000);
        });
      } else {

        // wechatdevice({
        //   devicename: this.inWX() && 'mobile' || 'pc',
        //   tag_email: this.ruleForm.email || this.userinfo.email || this.emailRuleForm.email,
        //   redirect_url: this.inWX() && 'https://account.meshmellow.cn/login?step=5' || '',
        // }).then((res) => {
        //   if (this.inWX()) {
        //     location.href = res.redirect
        //   }
        //   this.qrUrl = res.url;
        //   // console.log(this.inWX(),321);
        //   let refreshRequest = setInterval(() => {
        //     wechatget_auth_information({
        //       tag_email: this.ruleForm.email || this.userinfo.email || this.emailRuleForm.email,
        //     }).then((res) => {
        //       if (res.success) {
        //         this.$msg('授权成功');
        //         this.wxauthorization = res.message
        //         this.visible = false
        //         clearInterval(refreshRequest);
        //         // this.afterGotToken(res.token);
        //       } else {
        //         if (res.message[1]) {
        //           this.$msg(res.message);
        //         }
        //         // this.$msg(res.message);
        //         // this.visible = false
        //         // clearInterval(refreshRequest);
        //       }
        //       if (!this.visible) {
        //         clearInterval(refreshRequest);
        //       }
        //     });
        //   }, 2000);
        // })
      }


    },
    /*
     * @param:formName
     * 点击登录按钮
     */
    submit() {
      // console.log(this.type,9889);
      this.$refs[this.type].validate((valid) => {
        if (valid) {
          // delCookie('token')
          deleteAllTokens();
          if (this.type === "nameRuleForm" || this.type === "mNameRuleForm") {
            loginByUsername({
                username: this.nameRuleForm.name,
                password: this.nameRuleForm.psw,
                lang: localStorage.lang,
              })
              .then((res) => {
                // console.log(res, 8897987); 
                // this.tokens = res.token
                this.getuserinfo(res.token)
                return;
                this.afterGotToken(res.token);
              })
              .catch((err) => {
                // console.log(err,123434);
                catchErr(err, this);
              });
          } else if (
            this.type === "phoneRuleForm" ||
            this.type === "mPhoneRuleForm"
          ) {
            console.log('====================================');
            console.log(123);
            console.log('====================================');
            loginByTel({
                tel: this.phoneRuleForm.phone,
                code: this.phoneRuleForm.code,
                lang: localStorage.lang,
              })
              .then((res) => {
                this.getuserinfo(res.token)
                return;
                this.afterGotToken(res.token);
              })
              .catch((err) => {
                catchErr(err, this);
              });
          } else if (
            this.type === "emailRuleForm" ||
            this.type === "mEmailRuleForm"
          ) {
            loginByEmail({
                email: this.emailRuleForm.email,
                password: this.emailRuleForm.psw,
                lang: localStorage.lang,
              })
              .then((res) => {

                this.getuserinfo(res.token)
                return;
                this.afterGotToken(res.token);
              })
              .catch((err) => {
                catchErr(err, this);
              });
          }
          this.errReset();
        } else {
          return false;
        }
      });
    },
    /**
     * resetForm()：表单重制
     */
    errReset() {
      this.usernameErr = "";
      this.passwordErr = "";
      this.telErr = "";
      this.emailErr = "";
      this.codeErr = "";
    },
    /**
     * tabs切换
     * @param name
     */
    tabChange(name) {
      this.errReset();
      this.$refs[this.type].resetFields();
      this.$refs[this.type].clearValidate();
      this.type = name;
    },
    registerTypeChange(v) {
      console.log(v, 123);
      this.errReset();
      this.$refs["ruleForm"].clearValidate();
      this.$refs["mRuleForm"].clearValidate();
      this.ruleForm = {
        name: "",
        psw: "",
        email: "",
        confirmPsw: "",
        phone: "",
        code: "",
        code2: '',
        code3: '',
      };
    },
  },
  watch: {
    visible(val) {
      if (val === true) {
        // console.log(this.devicename, this.ruleForm.email);
        localStorage.setItem('ruleForm', JSON.stringify(this.ruleForm));
        if (this.$route.path == '/register') {
          wechatdevice({
            devicename: this.inWX() && 'mobile' || 'pc',
            tag_email: this.ruleForm.email,
            redirect_url: this.inWX() && 'https://account.meshmellow.cn/register?step=4' || '',
          }).then((res) => {
            if (this.inWX()) {
              location.href = res.redirect
            }
            this.qrUrl = res.url;
            // console.log(this.inWX(),321);
            let refreshRequest = setInterval(() => {
              wechatget_auth_information({
                tag_email: this.ruleForm.email,
              }).then((res) => {
                if (res.success) {
                  this.uerror = null
                  this.wxauthorization = res.message
                  this.visible = false
                  clearInterval(refreshRequest);
                  // this.afterGotToken(res.token);
                } else {

                  this.$msg(res.message);
                  this.uerror = res.message

                  this.visible = false

                }
                if (!this.visible) {
                  clearInterval(refreshRequest);
                }
              });
            }, 3000);
          })
        } else {
          this.wxLogin();
        }
        // return;
      }
    },
    // '$router.query': function (newUid) {
    //   // this.uid = newUid;
    //   // this.handleRouteChange();
    //   console.log(1234);
    // }

  },
};