import {
    get,
    post
} from "./axios";

// 账号密码登入
export const loginByUsername = (data) => post("/login/", data);

// 获取登陆手机验证码
export const sendLoginCode = (data) => post("/login/tel/code", data);

// 手机号登陆
export const loginByTel = (data) => post("/login/tel", data);

// 邮箱登陆
export const loginByEmail = (data) => post("/login/email", data);

//  微信登陆
export const loginByWX = (data) => post("/login/wechat", data);

//  账号密码注册
export const registerByUserName = (data) => post("/register", data);

//  邮箱注册
export const registerByEmail = (data) => post("/register/email", data);





//  手机号注册
export const registerByPhone = (data) => post("/register/tel", data);

//  发送重制密码手机验证码
export const sendResetPswCode = (data) => post("/reset/tel/code", data);

//  发送重制密码邮箱验证码
export const sendResetPswEmailCode = (data) => post("/reset/email/code", data);

//  通过手机验证码修改密码
export const changePswByCode = (data) => post("/reset/tel", data);

//  通过邮箱验证码修改密码
export const changePswByEmailCode = (data) => post("/reset/email", data);

//  获取微信qrcode
export const getWxQrCode = (data) => get("/wechat/qrCode", data);

//  微信回调
export const WxCallbak = (data) => get("/wechat/user", data);

export const authCheck = () => get("/user/check");

// 
export const wechatgetLoginUrl = (data) => post("/wechat/getLoginUrl", data)
// 获取登录者的ip地址
export const getips = (data) => post(`/ip/cname`, data)





// 改版后的接口  注册模块
// 用户名检测唯一性
export const registerdetect_username = (data) => post('/register/detect_username', data)
//  发送手机号注册短信验证码
export const sendRegisterCode = (data) => post("/register/tel/code", data);
//  验证手机号验证码是否有效
export const registerconfirmed_tel_code = (data) => post("/register/confirmed_tel_code", data)
// 发送邮箱注册短信验证码
//  发送邮箱注册验证码
export const sendRegisterEmailCode = (data) => post("/register/email/code", data);
// export const registeremailcode = (data) => post("/register/email/code", data)
// 验证邮箱验证码是否有效
export const registerconfirmed_email_code = (data) => post('/register/confirmed_email_code', data)
// 用户授权操作
export const wechatdevice = (data) => get("/wechat/device", data)
// 获取用户授权信息
export const wechatget_auth_information = (data) => post("/wechat/get_auth_information", data)
// 提交注册表单的接口
export const registerwrite = (data) => post("/register/write", data)
// 查询当前用户是否关注了公众号
export const wechatis_subscribe_wechat_official_account = (data) => post('/wechat/is_subscribe_wechat_official_account', data)

// 改版后的接口  
export const userinfo = (data) => get('/user/info', data)
export const usersendcode_bounduid = (data) => post('/user/sendcode_bounduid', data)
export const accormerge = (data) => post('/user/mergeid', data)
// 个人中心通知公众号二维码
export const wenotice = (data)=> post(`/user/wechat_bound`, data)